<template>
	<div class="container">
		<div class="title">
			<div>PROJECT SUBMISSION</div>
			<div class="sub-title">Please tell us about your project / company</div>
		</div>
		<div class="form">
			<!-- <transition-group name="fade" mode="out-in"> -->
			<form @submit.prevent v-if="state.step === 1">
				<div class="form-group">
					<input type="text" placeholder="Name of your Company" v-model="state.form.companyName" />
				</div>
				<div class="form-group">
					<input
						type="text"
						placeholder="Name of your Project (if different from your company name)"
						v-model="state.form.projectName"
					/>
				</div>
				<div class="form-group">
					<input type="text" placeholder="Your Name/Role" v-model="state.form.role" />
				</div>
				<div class="form-group">
					<input type="email" placeholder="Your Email Address" v-model="state.form.email" />
				</div>
			</form>
			<form @submit.prevent v-if="state.step === 2">
				<div>
					<div class="label">
						Kindly provide a brief overview of your project (within 500 characters)
					</div>
					<textarea class="form-textarea" maxlength="500" v-model="state.form.brief" />
				</div>
				<div>
					<div class="label">Summary of Highlights of your project (within 500 characters)</div>
					<textarea class="form-textarea" maxlength="500" v-model="state.form.highLights" />
				</div>
			</form>
			<form @submit.prevent v-if="state.step === 3">
				<div>
					<div class="label">
						Please share what differentiates your project from other similar projects.(within 800
						characters)
					</div>
					<textarea class="form-textarea" maxlength="800" v-model="state.form.different" />
				</div>
				<div>
					<div class="label">
						Please share what would give your project long term defensibility ie the moat for your
						project.(within 800 characters)
					</div>
					<textarea class="form-textarea" maxlength="800" v-model="state.form.defensibility" />
				</div>
			</form>
			<form @submit.prevent v-if="state.step === 4">
				<div class="label">
					Please upload your presentation decks and / or game play or product screenshots. Make sure
					your file is in one of the accepted formats: .jpg, .png, .pdf, .doc, .docx, .xls, or
					.xlsx. The maximum file size per upload is 10MB and 5 files in total are allowed.
				</div>
				<div class="form-group form-group4">
					<div class="upload-container">
						<img class="upload-bg" src="../../assets/portfolio/upload_bg.svg" alt="" />
						<img class="upload-bg-h5" src="../../assets/h5portfolio/upload_bg.png" alt="" />
						<input
							class="upload-input bg-input"
							type="file"
							accept=".jpg, .png, .pdf, .doc, .docx, .xls, .xlsx"
							maxlength="5"
							@change="handleSuccess"
						/>
					</div>
					<span class="upload-length">{{ state.form.fileUrls.length }}/5</span>
				</div>
			</form>
			<div class="form5" v-if="state.step === 5">
				<div class="form5title">Preview</div>
				<div class="form5body">
					<div>
						Name of your Company: <span>{{ state.form.companyName }}</span>
					</div>
					<div>
						Name of your Project(If Different from you company name):
						<span>{{ state.form.projectName }}</span>
					</div>
					<div>
						Your Name / Role: <span>{{ state.form.role }}</span>
					</div>
					<div>
						Your email: <span>{{ state.form.email }}</span>
					</div>
					<div>
						Kindly provide a brief overview of your project: <span>{{ state.form.brief }}</span>
					</div>
					<div>
						Summary of Highlights of your project: <span>{{ state.form.highLights }}</span>
					</div>
					<div>
						Differentiation of your projects vs. others: <span>{{ state.form.different }}</span>
					</div>
					<div>
						Long term defensibility / moat for your project:
						<span>{{ state.form.defensibility }}</span>
					</div>
				</div>
			</div>

			<!-- </transition-group> -->
			<div class="btn-group">
				<button class="btn" @click="back">BACK</button>
				<button class="btn btn-primary" @click="next">NEXT</button>
			</div>
			<div class="step-process">
				<div
					class="point"
					:style="{
						marginLeft: `${
							stepProcessMap[state.step] === 100 ? 98.6 : stepProcessMap[state.step]
						}%`,
					}"
				>
					<div class="text">{{ stepProcessMap[state.step] }}%</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { reactive, defineEmits } from 'vue';
import { submitData, upload } from '@/api/index';

const stepProcessMap = {
	1: 0,
	2: 25,
	3: 50,
	4: 75,
	5: 100,
};

const state = reactive({
	step: 1,
	form: {
		companyName: '',
		projectName: '',
		role: '',
		email: '',
		brief: '',
		highLights: '',
		different: '',
		defensibility: '',
		fileUrls: [],
	},
});

const emit = defineEmits(['back']);

const back = () => {
	if (state.step === 1) {
		return emit('back2content');
	}
	state.step--;
};

const handleSuccess = (e) => {
	if (state.form.fileUrls.length <= 5) {
		var file = e.target.files[0];
		var rd = new FileReader();
		rd.readAsDataURL(file);
		rd.onloadend = () => {
			var url = rd.result;
		};
		let formData = new FormData();
		formData.append('files', file);
		console.log(formData, 'formData');
		upload(formData).then((res) => {
			state.form.fileUrls.push(res.urls);
		});
	} else {
		return;
	}
};

const next = () => {
	if (state.step === 4) {
		state.form.fileUrls = JSON.stringify(state.form.fileUrls);
		submitData(state.form).then((res) => {
			console.log(res);
		});
	}
	if (state.step === 5) {
		return emit('next2finish');
	}
	state.step++;
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 768px) {
	.container {
		width: 100%;
		color: #ffffff;
		background: rgba(0, 0, 0, 1);
		padding: 0 8px;
		position: relative;
		.title {
			width: 100%;
			height: 369px;
			background: url('../../assets/h5portfolio/banner.png');
			background-size: 100% 369px;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			font-weight: 700;
			font-size: 24px;
			padding-top: 91px;

			.sub-title {
				width: 220px;
				font-size: 16px;
				margin-top: 13px;
			}
		}

		.form {
			width: 100%;
			min-height: 344px;
			overflow: hidden;
			background-image: url('~@/assets/h5portfolio/form_bg.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			padding: 38px 13px 0;
			margin-top: -144px;
			.btn-group {
				width: calc(100% - 26px);
				position: absolute;
				display: flex;
				justify-content: space-between;
				padding: 0 14px 0 0;
				bottom: -48px;

				.btn {
					font-size: 10px;
					width: 108px;
					height: 34px;
					line-height: 14px;
					border: none;
					padding: 10px 40px;
					color: black;
					background-color: #fff;
					cursor: pointer;
					font-weight: bold;
					// color: #333333;

					&:active {
						background-color: #b4b4b4;
					}
				}
				.btn-primary {
					color: white;
					background-color: #1f78d3;

					&:active {
						background: rgb(18, 78, 138);
					}
				}
			}

			.form-group {
				background-color: #d9d9d9;
				border-radius: 2px;
				margin-bottom: 24px;
				width: 100%;
				// height: 40px;
				position: relative;

				input {
					box-sizing: border-box;
					background-color: #d9d9d9;
					border: none;
					width: 100%;
					height: 40px;
					padding: 12px 16px;

					font-weight: bold;
					font-size: 10px;

					&:placeholder {
						color: #676767;
					}
				}

				.upload-container {
					width: 100%;
					height: 162px;
					margin: auto;
					position: relative;
					display: inline-block;
					background: rgba(39, 39, 39, 1);
					padding: 12px 22px;

					.upload-bg {
						display: none;
					}
					.upload-bg-h5 {
						box-sizing: border-box;
						width: 100%;
						height: 137px;
						background-color: rgba(76, 76, 76, 1);
						border: 1px dashed #000;
						display: block;
						margin: 0 auto;
					}

					.upload-input {
						box-sizing: border-box;
						width: 100% !important;
						height: 162px !important;
						opacity: 0;
						inset: 0;
						position: absolute;
						z-index: 10;
						margin: 0 auto;
						cursor: pointer;
					}
				}
			}
			.form-group4 {
				margin-top: 8px;
				background: rgba(39, 39, 39, 1);
				.upload-length {
					position: absolute;
					right: 1px;
					bottom: 0px;
					color: #ffffff;
					font-weight: 400;
					font-size: 14px;
				}
			}

			.label {
				font-size: 10px;
				font-weight: 400;
				margin-bottom: 10px;
				line-height: 12.5px;
			}

			.form-textarea {
				box-sizing: border-box;
				background-color: #d9d9d9;
				border: none;
				resize: none;
				display: block;
				width: 100%;
				height: 82px;
				padding: 10px;
				border-radius: 2px;
				margin-bottom: 16px;
				font-size: 10px;
			}

			.step-process {
				width: 100%;
				height: 1px;
				background: linear-gradient(90deg, #fdaa33 0%, #1f78d3 100%);
				border-radius: 100px;
				position: relative;
				display: flex;
				align-items: center;
				margin: 37px 0 20px;

				.point {
					width: 5.36px;
					height: 5.36px;
					background-color: #ffffff;
					box-shadow: 0px 4px 4px 0px #fdaa3340 inset;
					border-radius: 100px;
					position: relative;

					.text {
						position: absolute;
						top: 100%;
						color: #fff;
						font-size: 8.52px;
						font-weight: 500;
						text-align: left;
						margin-left: -5px;
					}
				}
			}
			.form5 {
				font-size: 10px;
				font-weight: 400;
				.form5title {
					font-size: 14px;
				}
				.form5body {
					div {
						padding: 6px 10px;
						margin-top: 10px;
						border-radius: 2px;
						background: rgba(39, 39, 39, 1);
						span {
							font-weight: bold;
						}
					}
				}
			}
		}
	}
}
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.container {
		padding: 82px 333px 134px;
		background-color: rgba(21, 21, 21, 1);
		color: white;
		overflow: hidden;

		.title {
			text-align: center;
			font-size: 64px;
			font-weight: 500;

			.sub-title {
				margin: 14px 0 61px;
				font-size: 32px;
			}
		}

		.form {
			height: 816px;
			overflow: hidden;
			background-image: url('~@/assets/portfolio/form_bg.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			padding: 50px 90px 0;
			border-right: 10px;
			.form-group {
				background-color: #d9d9d9;
				padding: 20px;
				border-radius: 10px;
				margin-bottom: 33px;
				position: relative;

				input {
					background-color: #d9d9d9;
					border: none;
					width: 100%;
					height: 40px;
					font-weight: bold;
					font-size: 16px;

					&:placeholder {
						color: #676767;
					}
				}
			}
			.form-group4 {
				margin-top: 20px;
				text-align: center;
				.upload-length {
					position: absolute;
					right: 20px;
					bottom: 10px;
					color: #000;
					font-weight: bold;
				}
			}

			.label {
				font-size: 16px;
				font-weight: bold;
				margin-bottom: 10px;
			}

			.form-textarea {
				background-color: #d9d9d9;
				border: none;
				resize: none;
				display: block;
				width: 100%;
				height: 200px;
				padding: 20px;
				border-radius: 10px;
				margin-bottom: 20px;
				font-size: 16px;
				margin-top: 10px;
			}

			.form5 {
				font-size: 10px;
				font-weight: 400;
				.form5title {
					font-size: 20px;
				}
				.form5body {
					font-size: 16px;
					line-height: 35px;
					font-weight: bold;
				}
			}
		}
	}

	.btn-group {
		display: flex;
		justify-content: space-between;
		margin-top: 32px;
		padding: 0 20px;
	}

	.btn {
		border: none;
		padding: 16px 20px;
		color: black;
		background-color: #fff;
		cursor: pointer;
		font-weight: bold;

		&:active {
			background-color: #b4b4b4;
		}
	}

	.btn-primary {
		color: white;
		background-color: #1f78d3;

		&:active {
			background: rgb(18, 78, 138);
		}
	}

	.step-process {
		width: 100%;
		height: 10px;
		background: linear-gradient(90deg, #fdaa33 0%, #1f78d3 100%);
		border-radius: 100px;
		position: relative;
		display: flex;
		align-items: center;
		margin-top: 55px;

		.point {
			width: 16px;
			height: 16px;
			background-color: #ffffff;
			box-shadow: 0px 4px 4px 0px #fdaa3340 inset;
			border-radius: 100px;
			position: relative;

			.text {
				position: absolute;
				top: 100%;
				color: #fff;
				font-size: 16px;
				font-weight: bold;
				text-align: center;
				margin-left: -6px;
			}
		}
	}

	.upload-container {
		width: 100%;
		height: 318px;
		margin: auto;
		position: relative;
		display: inline-block;

		.upload-bg-h5 {
			display: none;
		}
		.upload-bg {
			width: 822px;
			height: 318px;
			background-color: #29292980;
			border: 1px dashed #000;
			display: block;
			margin: 0 auto;
		}

		.upload-input {
			width: 822px !important;
			height: 318px !important;
			opacity: 0;
			inset: 0;
			position: absolute;
			z-index: 10;
			margin: 0 auto;
			cursor: pointer;
		}
	}
}
</style>
