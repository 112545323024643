<template>
	<div class="dialog">
		<div class="title">
			<div class="font1">PROJECT SUBMISSION</div>
			<div class="font2">
				Your submission has been received for our review. Thank you so much for taking the time!
			</div>
		</div>
		<div class="box">
			<div class="button left" @click="back">BACK TO PORTFOLIO</div>
			<div class="button" @click="next">SUBMISSION NEW PROJECT</div>
		</div>
	</div>
</template>

<script setup>
import { defineEmits } from 'vue';
const emit = defineEmits(['back']);

const back = () => {
	return emit('back2content');
};

const next = () => {
	return emit('againfrom');
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media screen and (max-width: 768px) {
	.dialog {
		width: 100%;
		height: 369px;
		background: rgba(0, 0, 0, 1);
		background: url('../../assets/h5portfolio/banner.png');
		padding-top: 91px;
		background-size: 100% 369px;
		.title {
			width: 100%;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			color: #ffffff;

			.font1 {
				font-weight: 700;
				font-size: 24px;
			}
			.font2 {
				width: 220px;
				font-size: 16px;
				margin-top: 13px;
			}
		}
		.box {
			width: 100%;
			margin-top: 30px;
			display: flex;
			justify-content: space-between;
			padding: 0 16px;
			.button {
				padding: 10px 20px;
				line-height: 14px;
				height: 34px;
				font-size: 10px;
				font-weight: 500;
				background: rgba(31, 120, 211, 1);
				color: #ffffff;
				border-radius: 4px;
			}
			.left {
				background: rgba(255, 255, 255, 1);
				color: rgba(0, 0, 0, 1);
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.dialog {
		width: 100%;
		height: 1097px;
		background: url('../../assets/portfolio/dialog.png') 100% 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #ffffff;
		// padding: 82px 0 0 0;
		.font1 {
			margin-top: 82px;
			font-size: 64px;
			font-weight: 500;
		}
		.font2 {
			width: 805px;
			text-align: center;
			margin-top: 176px;
			font-size: 32px;
			font-weight: 500;
		}
		.box {
			width: 512px;
			margin-top: 82px;
			display: flex;
			justify-content: space-between;
			.button {
				padding: 10px 20px;
				line-height: 14px;
				height: 34px;
				font-size: 14px;
				font-weight: 500;
				background: rgba(31, 120, 211, 1);
			}
			.left {
				background: rgba(255, 255, 255, 1);
				color: rgba(0, 0, 0, 1);
			}
		}
	}
}
</style>
