<template>
	<div class="portfolio">
		<div class="banner">
			<div class="left">
				{{ $t('Portfolio.title') }}
			</div>
			<div class="right">
				<div class="font1">
					{{ $t('Portfolio.slogan1') }}
				</div>
				<div class="flex-box">
					<div class="button" @click="emit('submission')">
						{{ $t('Portfolio.submit') }}
					</div>
					<div class="font2">
						{{ $t('Portfolio.slogan2') }}
					</div>
				</div>
			</div>
		</div>
		<div class="body">
			<div class="mar">
				<div class="border-box" v-for="(word, index) in helloArray" :key="index">
					{{ word }}
				</div>
			</div>
			<div class="box">
				<div class="item">
					<img src="@/assets/portfolio/item.png" alt="" class="top" />
					<div class="bottom">
						The First MediaFi Company in Web3, Producing media content with innovative financial
						ecosystem transforming video games, music, film & more.
					</div>
				</div>
				<!-- <div class="item">
					<img src="@/assets/portfolio/item.png" alt="" class="top" />
					<div class="bottom">
						The First MediaFi Company in Web3, Producing media content with innovative financial
						ecosystem transforming video games, music, film & more.
					</div>
				</div>
				<div class="item">
					<img src="@/assets/portfolio/item.png" alt="" class="top" />
					<div class="bottom">
						The First MediaFi Company in Web3, Producing media content with innovative financial
						ecosystem transforming video games, music, film & more.
					</div>
				</div> -->
			</div>
		</div>
		<div class="bottom_box">
			<div class="item" v-for="(item, index) in arr" :key="index">
				<img :src="item.url" alt="" class="top" />
				<div class="font1">{{ item.title }}</div>
				<div class="font2">{{ item.font }}</div>
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, defineEmits } from 'vue';

import top1 from '@/assets/nav/mc2.png';
import top2 from '@/assets/nav/mp4.png';
import top3 from '@/assets/portfolio/icon3.png';
import top4 from '@/assets/portfolio/icon4.png';
import top5 from '@/assets/nav/mp3.png';
import top6 from '@/assets/portfolio/icon6.png';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const helloArray = ['ALL', 'DeFi', 'GameFi', 'MediaFi', 'socialFi', 'RWA', 'MetaVerse'];

const emit = defineEmits(['submission']);

let arr = ref([
	{
		url: top1,
		title: t('Portfolio.list1.title'),
		font: t('Portfolio.list1.font'),
	},
	{
		url: top2,
		title: t('Portfolio.list2.title'),
		font: t('Portfolio.list2.font'),
	},
	{
		url: top3,
		title: t('Portfolio.list3.title'),
		font: t('Portfolio.list3.font'),
	},
	{
		url: top4,
		title: t('Portfolio.list4.title'),
		font: t('Portfolio.list4.font'),
	},
	{
		url: top5,
		title: t('Portfolio.list5.title'),
		font: t('Portfolio.list5.font'),
	},
	{
		url: top6,
		title: t('Portfolio.list6.title'),
		font: t('Portfolio.list6.font'),
	},
]);
</script>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
	.portfolio {
		width: 100%;
		color: #ffffff;
		background: rgba(0, 0, 0, 1);
		.banner {
			width: 100%;
			height: 369px;
			background: url('../../assets/h5portfolio/banner.png');
			background-size: 100% 369px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0;
			.left {
				margin-bottom: 13px;
				font-size: 24px;
			}
			.right {
				text-align: center;
				font-weight: 700;
				display: flex;
				flex-direction: column;
				align-items: center;
				.font1 {
					width: 300px;
					margin-bottom: 64px;
					font-size: 16px;
				}
				.font2 {
					margin-top: 21px;
					font-size: 14px;
					font-weight: 400;
				}
				.button {
					width: 318px;
					height: 48px;
					background: rgba(217, 217, 217, 0.2);
					line-height: 48px;
					text-align: center;
					font-size: 20px;
					border-radius: 60px;
					border: 1px solid rgba(255, 255, 255, 1);
					border-top: 0;
					border-bottom: 0;
				}
			}
		}

		.body {
			width: 100%;
			padding: 0 15px 0px;
			margin-top: -23px;
			display: flex;
			flex-direction: column;
			.mar {
				width: 100%;
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
				cursor: default;
				.border-box {
					padding: 17px 16px;
					font-size: 14px;
					line-height: 14px;
					font-weight: 500;
					cursor: pointer;
					border: 1px solid transparent;
					border-radius: 10px;
					background-clip: padding-box, border-box;
					background-origin: padding-box, border-box;
					background-image: linear-gradient(to right, #323232, #323232),
						linear-gradient(90deg, #969696, #323232, #323232, #969696);

					margin-bottom: 16px;
				}
				.border-box:hover {
					background-image: linear-gradient(to right, #2a7bcc, #fdaa33),
						linear-gradient(90deg, #969696, #323232, #323232, #969696);
				}
			}

			.box {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				margin-top: 28px;
				justify-content: space-between;
				margin-bottom: 83px;

				.item {
					width: 100%;
					height: 292px;
					border: 1px solid rgba(27, 114, 203, 1);
					margin-bottom: 60px;
					border-radius: 20px;

					.top {
						width: 100%;
						height: 211.54px;
						border-radius: 20px;
					}

					.bottom {
						width: 100%;
						height: 79.69px;
						font-size: 13px;
						font-weight: 400;
						padding: 10px 10px 0;
					}
				}
				.item:last-child {
					margin-bottom: 0;
				}
			}
		}

		.bottom_box {
			width: 100%;
			padding: 0 15px;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;

			.item {
				width: 100%;
				// height: 290px;
				border: 1px solid rgba(27, 114, 203, 1);
				margin-bottom: 50px;
				padding: 32px 4px 40px 20px;
				border-radius: 10px;

				.top {
					width: 50px;
					margin-bottom: 20px;
				}

				.font1 {
					font-size: 24px;
					font-weight: 500;
					margin-bottom: 20px;
				}

				.font2 {
					font-size: 16px;
					font-weight: 400;
					width: 300px;
				}
			}
			.item:last-child {
				margin-bottom: 0;
			}
		}
	}
}
/* 当前屏幕大于768px 应用媒体查询里面的样式 */

@media screen and (min-width: 768px) {
	.portfolio {
		width: 100%;
		background: #000000;
		color: #ffffff;

		.banner {
			width: 100%;
			height: 480px;
			background: url('~@/assets/portfolio/banner.png');
			background-size: 1920px 480px;
			padding: 198px 0 0 262px;
			display: flex;
			justify-content: space-between;

			.left {
				font-size: 64px;
				font-weight: 600;
				padding-top: 15px;
			}

			.right {
				width: 1180px;
				height: 255px;
				// background: #000000a4;
				padding: 33px 0 0 30px;

				.font1 {
					margin-bottom: 30px;
					font-size: 32px;
					font-weight: 500;
				}
				.flex-box {
					display: flex;
					align-items: center;
					// justify-content: center;

					.font2 {
						// margin-bottom: 30px;
						font-size: 20px;
						font-weight: 400;
						margin-left: 18px;
					}

					.button {
						width: 248px;
						height: 51px;
						background: rgba(217, 217, 217, 0.3);
						line-height: 51px;
						text-align: center;
						font-size: 20px;
						font-weight: 500;
						cursor: pointer;
						border: 2px solid rgba(255, 255, 255, 0.6);
						border-top: 0px;
						border-bottom: 0px;
						background: rgba(217, 217, 217, 0.3);
						border-radius: 20px;
					}

					.button:hover {
						border: 2px solid rgba(255, 255, 255, 0.6);
						border-top: 0px;
						border-bottom: 0px;
						background: linear-gradient(90deg, #2d7ccb 0%, #fbaa35 100%);
					}
				}
			}
		}

		.body {
			width: 100%;
			padding: 0 252px 134px;
			margin-top: -20px;

			.mar {
				width: 100%;
				height: 92px;
				display: flex;
				justify-content: space-between;
				cursor: default;
				margin-top: 2px;

				.border-box {
					padding: 20px 40px;
					font-size: 28px;
					line-height: 52px;
					font-weight: 500;
					cursor: pointer;
					border: 2px solid transparent;
					border-radius: 20px;
					background-clip: padding-box, border-box;
					background-origin: padding-box, border-box;
					background-image: linear-gradient(to right, #323232, #323232),
						linear-gradient(90deg, #969696, #323232, #323232, #969696);
				}
				.border-box:hover {
					background-image: linear-gradient(to right, #2a7bcc, #fdaa33),
						linear-gradient(90deg, #969696, #323232, #323232, #969696);
				}
			}

			.box {
				display: flex;
				flex-wrap: wrap;
				padding-top: 60px;
				justify-content: space-between;

				.item {
					width: 450px;
					height: 380px;
					border: 1px solid rgba(27, 114, 203, 1);
					margin-bottom: 60px;
					border-radius: 20px;

					.top {
						width: 100%;
						height: 275px;
						border-radius: 20px;
					}

					.bottom {
						width: 100%;
						// width: 418px;
						height: 105px;
						font-size: 16px;
						font-weight: 400;
						padding: 13px 20px 0;
					}
				}
			}
		}

		.bottom_box {
			width: 100%;
			padding: 0 252px 100px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.item {
				width: 450px;
				height: 290px;
				border: 1px solid rgba(27, 114, 203, 1);
				margin-bottom: 50px;
				padding: 44px 0 0 20px;
				border-radius: 20px;

				.top {
					width: 50px;
					margin-bottom: 14px;
				}

				.font1 {
					font-size: 24px;
					font-weight: 500;
					margin-bottom: 20px;
				}

				.font2 {
					font-size: 18px;
					font-weight: 400;
					width: 400px;
				}
			}
		}
	}
}
</style>
