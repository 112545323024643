<template>
  <transition-group name="fade" mode="out-in">
    <Content @submission="onSubmission" v-if="visible.content" />
    <Form @back2content="onBack2Content" @next2finish="onNext2finish" v-if="visible.form" />
    <Finish v-if="visible.finish" @back2content="onBack2Content" @againfrom="onSubmission" />
  </transition-group>
</template>

<script setup>
import { reactive } from 'vue'
import Content from './Content'
import Form from './Form'
import Finish from './Finish'




const visible = reactive({
  content: true,
  form: false,
  finish: false
})

const onSubmission = () => {
  visible.content = false
  visible.form = true
  visible.content = false
}

const onBack2Content = () => {
  visible.content = true
  visible.form = false
  visible.finish = false
}

const onNext2finish = () => {
  visible.content = false
  visible.form = false
  visible.finish = true
}

</script>
